const ADMINISTRACAO_GERAL = 1;
const SANTOS = 2;
const GUARULHOS = 3;
const TAMBORE = 4;
const CAMPINAS = 5;
const TN_LOGISTICA_TBE1 = 6;

const FilialMotoristaEnum = Object.freeze({
  1: "Administração Geral",
  2: "Santos",
  3: "Guarulhos",
  4: "Tamboré",
  5: "Campinas",
  6: "TN Logistica TBE1"
});

export function listaFiliais() {
    return [
        {
            valor: ADMINISTRACAO_GERAL,
            descricao: 'Administração Geral'
        },
        {
            valor: SANTOS,
            descricao: 'Santos'
        },
        {
            valor: GUARULHOS,
            descricao: 'Guarulhos'
        },
        {
            valor: TAMBORE,
            descricao: 'Tamboré'
        },
        {
            valor: CAMPINAS,
            descricao: 'Campinas'
        },
        {
            valor: TN_LOGISTICA_TBE1,
            descricao: 'TN Logistica TBE1'
        },
    ];
}
  
export default FilialMotoristaEnum;
