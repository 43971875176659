<template>
  <v-dialog v-model="dialog" persistent max-width="500">

    <template v-slot:activator="{on, attrs}">
      <slot></slot>
    </template>

    <template>
      <v-card title="Dialog">

        <v-card-title>
          <v-list-item class="grow">

            <span class="text-btn text-uppercase">Editar Permissão Selecionada</span>

            <v-row justify="end">
              <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                mdi-close-circle-outline
              </v-icon>
            </v-row>

          </v-list-item>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <form-editar-permissao :permissao="permissao" ref="refFormEditarPermissao"></form-editar-permissao>
        </v-card-text>

        <v-card-subtitle>
          <v-btn text color="success" class="ma-2 white--text mb-10" @click="disparaEventoAtualizarPermissao()">
            SALVAR
          </v-btn>

          <v-btn text plain color="red" class="ma-2 white--text mb-10" @click="eventoAbrirOuFecharModal()">
            CANCELAR
          </v-btn>
        </v-card-subtitle>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>

import FormEditarPermissao from '@/components/Permissao/Scaffold/FormEditarPermissao.vue'

export default {
  name: 'DialogEditarPermissao',
  components: {
    FormEditarPermissao,
  },
  data() {
    return {
      dialog: false,
      permissao: null
    }
  },
  methods: {
    eventoAbrirOuFecharModal() {
      this.dialog = !this.dialog  
    },

    disparaEventoAtualizarPermissao() {
      this.$refs.refFormEditarPermissao.eventoAtualizarPermissao()
    },

    // o componente pai aciona essa função passando os dados da permissao que foi selecionada
    // uma vez que recebemos esses dados eu armazeno no escopo do componente e passo como props para o componente filho
    passarValoresParaOFormDeEdicao(permissaoSelecionada) {

      //alterando o valor dos campos
      let permissao = {
        id_permissao:  permissaoSelecionada.id_permissao,
        nome:  permissaoSelecionada.nome,
        identificador:  permissaoSelecionada.identificador,
        cor: permissaoSelecionada.cor,
        acao:  permissaoSelecionada.acao_permissao,
        descricao:  permissaoSelecionada.descricao,
        icone:  permissaoSelecionada.icone
      }
      
      this.permissao = permissao
    },
  }
}
</script>