<template>
    <v-card elevation="5" class="mx-auto">
        <v-list class="pb-0" :color="corDoCardDeAcordoComTempoDaJornada" dense>
            <v-list-item>
                <v-list-item-avatar size="70">
                    <v-img :src="imagemAvatar(imagem_avatar)"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="body-1">
                    <strong>{{ nomeSobrenome }}</strong>
                    <v-list-item-subtitle>
                        <v-chip v-if="motorista_id_filial" class="ma-2" color="blue accent-2" label text-color="white">
                            <v-icon left small>
                                mdi-domain
                            </v-icon>
                            {{ nomeDafilialComBaseNoId(motorista_id_filial) }}
                        </v-chip>
                        <v-chip v-else class="ma-2" color="grey darken-1" label text-color="white">
                            <v-icon left small>
                                mdi-domain
                            </v-icon>
                                FILIAL NÃO CADASTRADA
                        </v-chip>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                        <v-chip v-if="telefone_celular" class="ma-2" color="red accent-4" label text-color="white">
                            <v-icon left small>
                                mdi-cellphone-wireless
                            </v-icon>
                            {{ telefone_celular | VMask('+55 (##) # ####-####') }}
                        </v-chip>

                        <v-chip v-else class="ma-2" color="grey darken-1" label text-color="white">
                            <v-icon left small>
                                mdi-cellphone-nfc-off
                            </v-icon>
                            SEM CADASTRO
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list v-if="!mostraTempoJornada" class="pt-0" dense>
            <v-list-item>
                <span>
                    <span class="font-weight-bold">JORNADA: </span>

                    <v-chip color="green darken-1" text-color="white" label small>
                        <v-icon left size="18">
                            mdi-timer-marker-outline
                        </v-icon>
                        {{ status_tempo_total }}
                    </v-chip>

                    <span class="font-weight-bold">&nbsp; GERAL: </span>

                    <v-chip color="warning darken-1" text-color="white" label small>
                        <v-icon left size="18">
                            mdi-timer-marker-outline
                        </v-icon>
                        {{ status_tempo_geral }}
                    </v-chip>
                </span>
            </v-list-item>
        </v-list>

        <v-list v-if="mostraTempoJornada" class="pt-0" :color="corDoCardDeAcordoComTempoDaJornada" dense>
            <v-list-item>
                <v-list-item-content class="body-2">
                    <span>
                        <span class="font-weight-bold">JORNADA: </span>

                        <v-chip color="green darken-1" text-color="white" label small>
                            <v-icon left size="18">
                                mdi-timer-marker-outline
                            </v-icon>
                            {{ status_tempo_total }}
                        </v-chip>

                        <span class="font-weight-bold">&nbsp; GERAL: </span>

                        <v-chip color="warning darken-1" text-color="white" label small>
                            <v-icon left size="18">
                                mdi-timer-marker-outline
                            </v-icon>
                            {{ status_tempo_geral }}
                        </v-chip>
                    </span>

                    <span>
                        <span class="font-weight-bold pt-2">PROGRAMAÇÃO CARGA:</span>
                        {{ id_programacao_carga_rodopar }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">ROMANEIO:</span>
                        {{ id_alocacao_carga_romaneio }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">CTe:</span>
                        {{ numero_cte }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">CLIENTE:</span>
                        {{ nome_tomador_servico }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">LOCAL COLETA:</span>
                        {{ terminal_coleta }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">LOCAL ENTREGA:</span>
                        {{ terminal_entrega }}
                    </span>

                    <span class="mt-1">
                        <span class="font-weight-bold">PLACA:</span>
                        {{ placa_veiculo_cavalo }}
                    </span>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>

import FilialMotoristaEnum from '@/Modulo/Monitoramento/Motorista/Dominio/Enum/FilialMotoristaEnum'

export default {
    name: 'MotoristaCard',
    props: {
        mostraTempoJornada: {
            type: Boolean,
            default: () => true
        },
        imagem_avatar: {
            type: String,
            default: () => null
        },
        motorista: {
            type: String,
            default: () => null
        },
        motorista_id_filial: {
            type: Number,
            default: () => null
        },
        telefone_celular: {
            type: String,
            default: () => null
        },
        status_tempo_total: {
            type: String,
            default: () => null
        },
        status_tempo_geral: {
            type: String,
            default: () => null
        },
        id_programacao_carga_rodopar: {
            type: Number,
            default: () => null
        },
        id_alocacao_carga_romaneio: {
            type: Number,
            default: () => null
        },
        numero_cte: {
            type: Number,
            default: () => null
        },
        nome_tomador_servico: {
            type: String,
            default: () => null
        },
        terminal_coleta: {
            type: String,
            default: () => null
        },
        terminal_entrega: {
            type: String,
            default: () => null
        },
        placa_veiculo_cavalo: {
            type: String,
            default: () => null
        },
        veiculo_frota: {
            type: String,
            default: () => null
        },
        veiculo_modelo: {
            type: String,
            default: () => null
        },
        corDoCardDeAcordoComTempoDaJornada: {
            type: String,
            default: () => null
        },
    },
    computed: {
        nomeSobrenome() {
            const palavras = this.motorista.split(' ');

            const nome = palavras[0];
            const sobrenome = palavras[palavras.length - 1];

            return `${nome} ${sobrenome}`;
        }
    },
    mounted() {
        this.alteraStatusTempoGeralDaJornadaTooltip() 
    },
    methods: {
        imagemAvatar(imagemAvatar) {
            return imagemAvatar ?? require('@/assets/image/perfil/img_avatar.png');
        },

        alteraStatusTempoGeralDaJornadaTooltip() {
            if(this.corDoCardDeAcordoComTempoDaJornada == 'orange lighten-4') {
                this.statusTempoGeralDaJornadaTooltip.color = 'orange'
                this.statusTempoGeralDaJornadaTooltip.message = 'O motorista está quase excedendo o tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
                return
            }

            if(this.corDoCardDeAcordoComTempoDaJornada == 'red lighten-4') {
                this.statusTempoGeralDaJornadaTooltip.color = 'red'
                this.statusTempoGeralDaJornadaTooltip.message = 'O motorista excedeu o tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
                return
            }

            this.statusTempoGeralDaJornadaTooltip.color = 'black'
            this.statusTempoGeralDaJornadaTooltip.message = 'O motorista está dentro do tempo limite de 11h em sua jornada (8h padrão, 3h extras)'
        },

        nomeDafilialComBaseNoId(id_filial) {            
            return FilialMotoristaEnum[id_filial]
        },
    },
    data() {
        return {
            show: false,
            statusTempoGeralDaJornadaTooltip: {
                color: '',
                message: ''
            },
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            cabecalho: [
                {
                    text: 'Ordem',
                    sortable: false,
                    value: 'ordem',
                    width: '80px'
                },
                {
                    text: 'Prog. Carga',
                    sortable: false,
                    value: 'id_programacao_carga_rodopar',
                    width: '100px'
                },
                {
                    text: 'CT-e',
                    sortable: false,
                    value: 'numero_cte',
                    width: '110px'
                },
                {
                    text: 'Cliente',
                    sortable: false,
                    value: 'cliente',
                    width: '150px'
                },
                {
                    text: 'Coleta',
                    sortable: false,
                    value: 'terminal_coleta',
                    width: '150px'
                },
                {
                    text: 'Entrega',
                    sortable: false,
                    value: 'terminal_entrega',
                    width: '150px'
                },
                {
                    text: 'Observação Entrega',
                    sortable: false,
                    value: 'observacao_gerais',
                    width: '150px'
                }
            ]
        };
    }
};
</script>
