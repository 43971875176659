<template>
  <div>
    <table-bar :icon="'mdi-lock'" :color="permissoes.length > 0 ? 'red lighten-5' : 'white'">
      <template v-slot:text>
        Essa tabela lista as permissões que podem ser atribuidas aos módulos
      </template>

      <template v-slot:buttons>

        <div class="d-flex">

          <div class="pr-2">
            <v-btn elevation="1" color="orange" class="white--text"
              @click="dispararEventoAtualizarTabela()">
              ATUALIZAR
              <v-icon right dark>
                mdi-reload
              </v-icon>
            </v-btn>
          </div>

          <div class="pr-2">
            <dialog-nova-permissao ref="DialogNovaPermissao">
              <v-btn elevation="1" color="green darken-3" class="white--text"
                @click="dispararEventoAbrirOuFecharModal('DialogNovaPermissao')">
                CRIAR PERMISSÃO
                <v-icon right dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </dialog-nova-permissao>
          </div>

          <div class="pr-2" v-if="permissoes.length > 0">
            <dialog-excluir-permissao ref="DialogExcluirPermissao" :permissoes="permissoes">
              <v-badge
                color="grey darken-1"
                overlap
              >
                <template v-slot:badge>
                  {{ permissoes.length }}
                </template>

                <v-btn elevation="1" color="red" class="white--text"
                @click="dispararEventoAbrirOuFecharModal('DialogExcluirPermissao')">
                EXCLUIR
                <v-icon right dark>
                  mdi-delete
                </v-icon>
              </v-btn>
              </v-badge>
            </dialog-excluir-permissao>
          </div>       

        </div>

      </template>

    </table-bar>
  </div>
</template>

<script>

import TableBar from '../../Shared/TableBar.vue';
import DialogNovaPermissao from '../Scaffold/DialogNovaPermissao.vue';
import DialogExcluirPermissao from '../Scaffold/DialogExcluirPermissao.vue';

export default {
  name: 'PermissaoTableBar',
  components: {
    TableBar,
    DialogNovaPermissao,
    DialogExcluirPermissao
  },
  props: [
    'permissoes'
  ],
  methods: {
    dispararEventoAtualizarTabela() {
      this.$emit('disparadoEventoAtualizarTabela')
    },

    dispararEventoAbrirOuFecharModal(refClicado) {
      this.$refs[refClicado].eventoAbrirOuFecharModal();
    },

    dispararEventoExcluirPermissoes() {
      console.log(this.permissoes);
    },
  }
}
</script>