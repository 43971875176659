<template>
  <div>
    <v-row class="pa-6">
      <v-col>
        <div ref="form">
          <v-text-field
            ref="nome"
            v-model="nome"
            :rules="[
              () => !!nome || campoObrigatorio,
              () => !!nome && nome.length <= 128 || 'O Nome deve ter menos de 128 caractéres'
            ]"
            :error-messages="errorMessages"
            label="Nome"
            placeholder="AcessaPainelDeExemplo"
            required
          ></v-text-field>
          
          <v-text-field
            ref="descricao"
            v-model="descricao"
            :rules="[
              () => !!descricao || campoObrigatorio,
              () => !!descricao && descricao.length <= 500 || 'A descrição deve ter menos de 500 caractéres'
            ]"
            label="Descrição"
            placeholder="Permissao para visualizar o Painel"
            counter="25"
            required
          ></v-text-field>
          <v-text-field
            ref="identificador"
            v-model="identificador"
            :rules="[
              () => !!identificador || campoObrigatorio,
              () => !!identificador && identificador.length <= 50 || 'O Identificador deve ter menos de 50 caractéres'
            ]"
            label="Identificador"
            required
            placeholder="modulo"
          ></v-text-field>
          <v-text-field
            ref="acao"
            v-model="acao"
            :rules="[
              () => !!acao || campoObrigatorio,
              () => !!acao && acao.length <= 50 || 'A Ação deve ter menos de 50 caractéres'
            ]"
            label="Ação"
            required
            placeholder="modulo"
          ></v-text-field>
          <v-text-field
            ref="cor"
            v-model="cor"
            :rules="[
              () => !!cor || campoObrigatorio,
              () => !!cor && cor.length <= 50 || 'A Cor deve ter menos de 50 caractéres'
            ]"
            label="Cor"
            placeholder="success"
            required
          ></v-text-field>
          <v-text-field
            ref="icone"
            v-model="icone"
            :rules="[
              () => !!icone || campoObrigatorio,
              () => !!icone && icone.length <= 50 || 'O Ícone deve ter menos de 50 caractéres'
            ]"
            label="Ícone"
            required
            placeholder="[]"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn 
            @click="resetForm"
            elevation="0"
            color="grey lighten-3"
            class="grey--text"
          >
            LIMPAR CAMPOS
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

        </div>
      </v-col>
    </v-row>

    <div>
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
        {{ notificacao.mensagem }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>
</template>

<script>
import eventBus from '@/config/eventBus'

import { mapActions, mapState } from 'vuex';
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
  
  export default {
    name: 'FormNovaPermissao',
    data: () => ({
      errorMessages: '',
      nome: null,
      descricao: null,
      cor: null,
      acao: null,
      identificador: null,
      icone: null,
      formHasErrors: false,
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
    }),
    watch: {
      name () {
        this.errorMessages = ''
      },
    },
    computed: {
      ...mapState(['carregando']),

      campoObrigatorio() {
        return 'Esse campo é obrigatório'
      },

      form () {
        let campos = {
          nome: this.nome,
          descricao: this.descricao,
          cor: this.cor,
          identificador: this.identificador,
          icone: this.icone,
          acao: this.acao,
        }
        return campos
      },
    },

    methods: {
      ...mapActions(['montaNavegacao', 'mostraCarregando']),

      resetForm() {
        this.errorMessages = '';
        this.formHasErrors = false;

        this.$refs.nome.reset()
        this.$refs.descricao.reset()
        this.$refs.cor.reset()
        this.$refs.acao.reset()
        this.$refs.identificador.reset()
        this.$refs.icone.reset()

        this.errorMessages = '';
      },

      async eventoNovaPermissao() {
        try {
          // this.mostraCarregando(true);
          await this.criarNovaPermissao();
        } 
        catch (erro) {
          this.notificacao = {
            mensagem: erro.message,
            tipo: 'error',
            mostra: true
          };
        } 
      },

      async criarNovaPermissao() {
        
        const url = `${API_URL.erp.permissaoNovo.novo}`;

        await ApiService({
          method: 'POST',
          url: url,
          data: {
            nome: this.nome,
            descricao: this.descricao,
            cor: this.cor,
            acao: this.acao,
            identificador: this.identificador,
            icone: this.icone,
          },
          mostraCarregando: true,
        })
        .then((resposta) => {
          this.resetForm();
          eventBus.$emit('evento-atualizar-tabela-de-permissoes', 'Permissão criada com sucesso!')
          this.mostraCarregando = false
          return resposta.data.data;
            
        })
        .catch((error) => {
          this.notificacao = {
            mensagem: error,
            tipo: 'error',
            mostra: true
          };
        });
      },
    },
  }
</script>
