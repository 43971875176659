var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"px-6 pt-6"},[_c('v-col',[_c('v-chip',{staticClass:"justify-center",staticStyle:{"width":"100%"},attrs:{"label":"","color":"grey lighten-3"}},[_c('strong',[_vm._v("ID: "+_vm._s(this.permissao.id_permissao))])])],1)],1),_c('v-row',{staticClass:"px-6"},[_c('v-col',[_c('div',{ref:"form"},[_c('v-text-field',{ref:"nome",attrs:{"rules":[
            () => !!_vm.nome || _vm.campoObrigatorio,
            () => !!_vm.nome && _vm.nome.length <= 128 || 'O Nome deve ter menos de 128 caractéres'
          ],"error-messages":_vm.errorMessages,"label":"Nome","placeholder":"AcessaPainelDeExemplo","required":""},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}}),_c('v-text-field',{ref:"descricao",attrs:{"rules":[
            () => !!_vm.descricao || _vm.campoObrigatorio,
            () => !!_vm.descricao && _vm.descricao.length <= 500 || 'A descrição deve ter menos de 500 caractéres'
          ],"label":"Descrição","placeholder":"Permissao para visualizar o Painel","counter":"25","required":""},model:{value:(_vm.descricao),callback:function ($$v) {_vm.descricao=$$v},expression:"descricao"}}),_c('v-text-field',{ref:"identificador",attrs:{"rules":[
            () => !!_vm.identificador || _vm.campoObrigatorio,
            () => !!_vm.identificador && _vm.identificador.length <= 50 || 'O Identificador deve ter menos de 50 caractéres'
          ],"label":"Identificador","required":"","placeholder":"modulo"},model:{value:(_vm.identificador),callback:function ($$v) {_vm.identificador=$$v},expression:"identificador"}}),_c('v-text-field',{ref:"acao",attrs:{"rules":[
            () => !!_vm.acao || _vm.campoObrigatorio,
            () => !!_vm.acao && _vm.acao.length <= 50 || 'A Ação deve ter menos de 50 caractéres'
          ],"label":"Ação","required":"","placeholder":"modulo"},model:{value:(_vm.acao),callback:function ($$v) {_vm.acao=$$v},expression:"acao"}}),_c('v-text-field',{ref:"cor",attrs:{"rules":[
            () => !!_vm.cor || _vm.campoObrigatorio,
            () => !!_vm.cor && _vm.cor.length <= 50 || 'A Cor deve ter menos de 50 caractéres'
          ],"label":"Cor","placeholder":"success","required":""},model:{value:(_vm.cor),callback:function ($$v) {_vm.cor=$$v},expression:"cor"}}),_c('v-text-field',{ref:"icone",attrs:{"rules":[
            () => !!_vm.icone || _vm.campoObrigatorio,
            () => !!_vm.icone && _vm.icone.length <= 50 || 'O Ícone deve ter menos de 50 caractéres'
          ],"label":"Ícone","required":"","placeholder":"[]"},model:{value:(_vm.icone),callback:function ($$v) {_vm.icone=$$v},expression:"icone"}}),_c('v-spacer'),_c('v-btn',{staticClass:"grey--text",attrs:{"elevation":"0","color":"grey lighten-3"},on:{"click":_vm.atribuiValoresPadraoAosCampos}},[_vm._v(" RESTAURAR CAMPOS PADRÃO "),_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)])],1),_c('div',[_c('v-snackbar',{attrs:{"multi-line":true,"outlined":"","color":_vm.notificacao.tipo,"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.notificacao.mostra = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.notificacao.mostra),callback:function ($$v) {_vm.$set(_vm.notificacao, "mostra", $$v)},expression:"notificacao.mostra"}},[_vm._v(" "+_vm._s(_vm.notificacao.mensagem)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }