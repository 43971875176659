<template>
  <div :class="classeDoAlerta">
    <v-alert
        dense
        color="grey darken-3"
        class="rounded white--text"
      >
        <slot>
        </slot>
      
        <v-chip
          color="grey darken-1"
          text-color="white"
        >
          <strong>
            {{ quantidade }} selecionado(s)
          </strong>
        </v-chip>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertaItensSelecionados',
  props: ['quantidade'],
  computed: {
    classeDoAlerta() {
      if (this.quantidade == 0 ) {
        return 'alerta-itens-selecionados invisivel'
      } 
      if (this.quantidade != 0) {
        return 'alerta-itens-selecionados visivel'
      }
    } 
  },
}
</script>

<style scoped>
  .alerta-itens-selecionados {
    position: absolute !important;
    left: 42%;
    bottom: 1%;
    align-content: center;
    text-align: center;
    justify-content: center;
    transition: 0.2s;
  }

  .visivel {
    opacity: 0.91;
  }

  .invisivel {
    opacity: 0;
  }

</style>  