<template>
  <div>
    <v-row class="px-6 pt-6">
      <v-col>
        <v-chip label color="grey lighten-3" style="width: 100%;" class="justify-center">
          <strong>ID: {{ this.permissao.id_permissao }}</strong>
        </v-chip>
      </v-col>
    </v-row>
    
    <v-row class="px-6">
      <v-col>
        <div ref="form">
          <v-text-field
            ref="nome"
            v-model="nome"
            :rules="[
              () => !!nome || campoObrigatorio,
              () => !!nome && nome.length <= 128 || 'O Nome deve ter menos de 128 caractéres'
            ]"
            :error-messages="errorMessages"
            label="Nome"
            placeholder="AcessaPainelDeExemplo"
            required
          ></v-text-field>
    
          <v-text-field
            ref="descricao"
            v-model="descricao"
            :rules="[
              () => !!descricao || campoObrigatorio,
              () => !!descricao && descricao.length <= 500 || 'A descrição deve ter menos de 500 caractéres'
            ]"
            label="Descrição"
            placeholder="Permissao para visualizar o Painel"
            counter="25"
            required
          ></v-text-field>
          <v-text-field
            ref="identificador"
            v-model="identificador"
            :rules="[
              () => !!identificador || campoObrigatorio,
              () => !!identificador && identificador.length <= 50 || 'O Identificador deve ter menos de 50 caractéres'
            ]"
            label="Identificador"
            required
            placeholder="modulo"
          ></v-text-field>
          <v-text-field
            ref="acao"
            v-model="acao"
            :rules="[
              () => !!acao || campoObrigatorio,
              () => !!acao && acao.length <= 50 || 'A Ação deve ter menos de 50 caractéres'
            ]"
            label="Ação"
            required
            placeholder="modulo"
          ></v-text-field>
          <v-text-field
            ref="cor"
            v-model="cor"
            :rules="[
              () => !!cor || campoObrigatorio,
              () => !!cor && cor.length <= 50 || 'A Cor deve ter menos de 50 caractéres'
            ]"
            label="Cor"
            placeholder="success"
            required
          ></v-text-field>
          <v-text-field
            ref="icone"
            v-model="icone"
            :rules="[
              () => !!icone || campoObrigatorio,
              () => !!icone && icone.length <= 50 || 'O Ícone deve ter menos de 50 caractéres'
            ]"
            label="Ícone"
            required
            placeholder="[]"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            @click="atribuiValoresPadraoAosCampos"
            elevation="0"
            color="grey lighten-3"
            class="grey--text"
          >
            RESTAURAR CAMPOS PADRÃO
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div>
      <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
        {{ notificacao.mensagem }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>

import eventBus from '@/config/eventBus'

import { mapActions, mapState } from 'vuex';
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
  
export default {
  name: 'FormEditarPermissao',
  data() {
    return {
      errorMessages: '',
      nome: null,
      descricao: null,
      cor: null,
      acao: null,
      identificador: null,
      icone: null,
      formHasErrors: false,
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      }
    }
  },
  props: [
    'permissao'
  ],
  mounted() {
    this.atribuiValoresPadraoAosCampos() 
    
  },
  watch: {
    name () {
      this.errorMessages = ''
    },

    permissao(newPermissao) {
      this.atribuiValoresPadraoAosCampos();
    }
  },
  computed: {
    ...mapState(['carregando']),

    campoObrigatorio() {
      return 'Esse campo é obrigatório'
    },

    form () {
      let campos = {
        nome: this.nome,
        descricao: this.descricao,
        cor: this.cor,
        identificador: this.identificador,
        icone: this.icone,
        acao: this.acao,
      }
      return campos
    },
  },

  methods: {
    ...mapActions(['montaNavegacao', 'mostraCarregando']),

    atribuiValoresPadraoAosCampos()  {

      this.nome = this.permissao.nome,
      this.descricao = this.permissao.descricao,
      this.cor = this.permissao.cor,
      this.acao = this.permissao.acao,
      this.identificador = this.permissao.identificador,
      this.icone = this.permissao.icone
      
    },

    async eventoAtualizarPermissao() {
      try {
        // this.mostraCarregando(true);
        await this.atualizaPermissao();
      } 
      catch (erro) {
        this.notificacao = {
          mensagem: erro.message,
          tipo: 'error',
          mostra: true
        };
      } 
    },

    async atualizaPermissao() {
      
      let url = `${API_URL.erp.permissaoNovo.atualiza}${this.permissao.id_permissao}`;
      
      let data = {
        nome: this.nome,
        descricao: this.descricao,
        cor: this.cor,
        acao: this.acao,
        identificador: this.identificador,
        icone: this.icone,
      }

      data = this.preparaDadosParaAtualizacao(data);

      await ApiService({
        method: 'PUT',
        url: url,
        data: data,
        mostraCarregando: true,
      })
      .then((resposta) => {
        eventBus.$emit('evento-atualizar-tabela-de-permissoes', 'Permissão atualizada com sucesso!')
        this.mostraCarregando = false
        return resposta.data.data;
      })
      .catch((error) => {
        this.notificacao = {
          mensagem: error,
          tipo: 'error',
          mostra: true
        };
      });
    },

    // preparando dados pois temos que enviar no PUT apenas os campos que foram modificados
    preparaDadosParaAtualizacao(dadosModificados) {

      let dadosAntigos = this.permissao
      let dadosQueSeraoAtualizados = {}

      for (let campo in dadosAntigos) {

        if (campo != 'id_permissao') {
          if (dadosAntigos[campo] !== dadosModificados[campo]) {
            dadosQueSeraoAtualizados[campo] = dadosModificados[campo] 
          }
        } 
      }
      return dadosQueSeraoAtualizados;
      
    }
  },
}
</script>
