<template>
    <v-dialog v-model="dialog" max-width="500">
  
        <template v-slot:activator="{on, attrs}">
            <slot></slot>
        </template>
  
        <template>
            <v-sheet color="grey darken-3" class="text-center">
                <v-card-title>
                    <v-list-item class="grow">
        
                    <span class="text-btn text-uppercase white--text">PERMISSÕES QUE SERÃO EXCLUIDAS</span>
    
                    <v-row justify="end">
                        <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                        mdi-close-circle-outline
                        </v-icon>
                    </v-row>
        
                    </v-list-item>
                </v-card-title>
    
                <v-divider color="grey" class="mb-4"></v-divider>

                <div class="px-6" style="max-height: 400px; overflow-y: auto;">
                    <v-alert v-for="permissao, key in permissoes" :key="key"
                        shaped
                        text
                        outlined
                        type="info"
                        class="text-left"
                        color="grey lighten-2"
                    >
                        <v-row>
                            <v-col cols="3"><strong>ID: {{permissao.id_permissao}}</strong></v-col>
                            <v-col cols="9"> {{ reduzTamanhoDaString(permissao.nome) }}</v-col>
                        </v-row>
                    </v-alert>
                </div>

                <v-divider color="grey" class="mb-2"></v-divider>

                <v-card-subtitle class="grey--text">
                    Tem certeza que deseja excluir os itens selecionados? (Essa ação não pode ser desfeita)
                </v-card-subtitle>

                <div class="d-flex px-2 pb-2 justify-center">
                    <v-btn plain color="success" class="white--text mr-2" @click="disparaEventoExcluirPermissoes()" style="width:48%;">
                        SIM
                    </v-btn>

                    <v-btn plain color="error" class=" white--text" @click="eventoAbrirOuFecharModal()" style="width:48%;">
                        NÃO
                    </v-btn>
                </div>
            </v-sheet>

            <div>
                <v-snackbar v-model="notificacao.mostra" :multi-line="true" outlined :color="notificacao.tipo" timeout="3000">
                    {{ notificacao.mensagem }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </template>
    </v-dialog>
</template>
  
<script>

import eventBus from '@/config/eventBus'

import { mapActions, mapState } from 'vuex';
import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';

export default {
    name: 'DialogExcluirPermissao',
    components: {
    },
    data() {
      return {
        dialog: false,
        notificacao: {
            mostra: false,
            mensagem: '',
            tipo: 'success'
        }
      }
    },
    props: [ 'permissoes' ],
    computed: {
        ...mapState(['carregando']),
    },
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),

        eventoAbrirOuFecharModal() {
            this.dialog = !this.dialog  
        },

        reduzTamanhoDaString(string) {
            if (string.length > 25) {
                return `${string.slice(0, 25)}...`
            }
            return string
        },

        async disparaEventoExcluirPermissoes() {

            let arrayDePermissoes = [];

            for (let permissao in this.permissoes) {
                let idDaPermissao = this.permissoes[permissao].id_permissao;
                arrayDePermissoes.push(idDaPermissao);
            }

            try {
                this.mostraCarregando = true
                if(arrayDePermissoes.length === 1) {
                    await this.eventoExcluirUmaPermissao(arrayDePermissoes[0])
                } 
                if(arrayDePermissoes.length > 1) {
                    await this.eventoExcluirMuitasPermissoes(arrayDePermissoes)
                } 
            }
            finally {      
                this.mostraCarregando = false
            }
        },

        async eventoExcluirMuitasPermissoes(arrayDePermissoes) {
            
            try {
                await ApiService({
                    method: 'DELETE',
                    url: `${API_URL.erp.permissaoNovo.apagaMuitasPermissoes}${arrayDePermissoes}`,
                    data: {},
                    mostraCarregando: false
                });
                eventBus.$emit('evento-atualizar-tabela-de-permissoes', 'Permissões excluídas com sucesso!')

                // return resposta;
            } catch (erro) {
                // Capture e trate o erro aqui, se ocorrer
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
                return erro
            }
        },

        async eventoExcluirUmaPermissao(idPermissao){

            try {
                await ApiService({
                    method: 'DELETE',
                    url: `${API_URL.erp.permissaoNovo.apaga}${idPermissao}`,
                    data: {},
                    mostraCarregando: false
                });
                eventBus.$emit('evento-atualizar-tabela-de-permissoes', 'Permissão excluída com sucesso!')

                // return resposta;
            } catch (erro) {
                // Capture e trate o erro aqui, se ocorrer
                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
                return erro
            }
        }
            
    },

  }
</script>