<template>
  <div>
    <v-banner
      :icon="icon"
      single-line
      elevation="1"
      :color="color"
      class="mb-3 table-bar"
    >
      <slot name="text"></slot>
    
      <template v-slot:actions>
        <slot name="buttons"></slot>
      </template>
      
    </v-banner>
  </div>
</template>

<script>
export default {
  name: 'TableBar',
  props: ['icon', 'color']
}
</script>

<style scoped>
  .table-bar {
    transition: 0.2s;
  }
</style>