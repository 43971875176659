<template>
  <div>
    <TituloModulo />

    <AlertaMensagem dense style="width: 100%" />

    <v-overlay :absolute="true" opacity="0.8" :value="carregando.mostraCarregando">
      <CarregandoLogoEmpresa :carregando="carregando.mostraCarregando" :mensagem="mensagemCarregando" />
    </v-overlay>

    <div v-if="permissao">
      
      <!-- Barra onde encontram-se os dialogs para clonagem e atribuição de perfis -->
      <!-- <usuario-perfil-table-bar v-if="!carregando.mostraCarregando && this.permissoes[0]['permitido'] == 1" -->
      <permissao-table-bar v-if="!carregando.mostraCarregando" @disparadoEventoAtualizarTabela="eventoAtualizarTabela" :permissoes="datatable.itensSelecionados"/>
      <v-row v-if="!carregando.mostraCarregando">
        <v-col cols="12" class="text-right">
          <TabelaVuetify v-model="datatable.itensSelecionados" :cabecalho="datatable.cabecalho" :itens="datatable.itens"
            :total_registros="datatable.totalRegistros" :por_pagina="datatable.por_pagina"
            :ultima_pagina="datatable.ultima_pagina" :carregando="datatable.carregando"
            :chave_primaria="datatable.chave_primaria" :ordenar_por="datatable.ordenarPor"
            :ordenar_direcao="datatable.ordenarDirecao" :mostra_selecao="true" @opcoes="buscaPermissoesLista"
            ref="tabelaPermissao">
            <template v-slot:item.acao="{ item }">
              <permissao-lapis-editar :permissao="item"></permissao-lapis-editar>
            </template>
          </TabelaVuetify>
        </v-col>
      </v-row>
    </div>

    <div class="text-center">
        <v-snackbar v-model="notificacao.mostra" :multi-line="true" :color="`${notificacao.tipo} accent-4`" elevation="10"
          timeout="3000" bottom text>
          <span v-html="notificacao.mensagem"></span>
          <template v-slot:action="{ on, attrs }">
            <v-btn color="red" text v-bind="attrs" v-on="on" @click="notificacao.mostra = false">
              Fechar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <alerta-permissoes-selecionadas :quantidade="datatable.itensSelecionados.length"/>
    
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import eventBus from '@/config/eventBus'

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import AlertaPermissoesSelecionadas from '@/components/Permissao/Embeeded/AlertaPermissoesSelecionadas.vue'
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import PermissaoTableBar from '../../components/Permissao/Embeeded/PermissaoTableBar.vue';
import PermissaoLapisEditar from '../../components/Permissao/Embeeded/PermissaoLapisEditar.vue';

import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
  name: 'Permissao',
  components: {
    AlertaMensagem,
    AlertaPermissoesSelecionadas,
    BarraNavegacaoBotaoV3,
    CarregandoLogoEmpresa,
    TituloModulo,
    TabelaVuetify,
    PermissaoTableBar,
    PermissaoLapisEditar
  },
  data() {
    return {
      permissao: true,
      botoes: [],
      permissoes: [],
      mensagemCarregando: 'Buscando, aguarde...',
      usuario: null,
      perfis: [],
      notificacao: {
        mostra: false,
        mensagem: '',
        tipo: 'success'
      },
      datatable: {
        carregando: false,
        mensagemCarregando: 'Buscando, aguarde...',
        chave_primaria: 'id_permissao',
        itens: [],
        totalRegistros: 0,
        ultima_pagina: 0,
        pagina_atual: 1,
        por_pagina: 50,
        ordenarPor: 'nome',
        ordenarDirecao: false,
        opcoes: {},
        itensSelecionados: [],
        cabecalho: [
          {
            text: 'AÇÃO',
            value: 'acao',
            align: 'center',
            width: '10',
            sortable: false,
            showFilter: false
          },
          {
            text: 'ID da Permissão',
            value: 'id_permissao',
            width: '200',
          },
          {
            text: 'Nome',
            value: 'nome',
            width: '150',
          },
          {
            text: 'Descrição',
            value: 'descricao',
            width: '150',
          },
          {
            text: 'Identificador',
            value: 'identificador',
            width: '100',
          },
          {
            text: 'Ação da Permissão',
            value: 'acao_permissao',
            width: '100',
          },
          {
            text: 'Cor',
            value: 'cor',
            width: '100',
          }, 
          {
            text: 'Icone',
            value: 'icone',
            width: '100',
          },
        ]
      },
    };
  },
  async mounted() {

    await this.carregaDados()

    /***
     * Escutando a o evento da permissao
     * caso a permissao seja atualizada com sucesso ele atualiza a tabela
     */
    eventBus.$on('evento-atualizar-tabela-de-permissoes', (mensagemDoEvento) => {
      this.buscaPermissoesLista()
      this.notificacao = {
        mostra: true,
        mensagem: mensagemDoEvento,
        tipo: 'success'
      }
      this.datatable.itensSelecionados = []
    })

  },

  beforeDestroy() {
    // removendo o escutador eventBus
    eventBus.$off('evento-atualizar-tabela-de-permissoes')
  },

  computed: {
    ...mapState(['carregando'])
  },

  watch: {},

  methods: {

    ...mapActions(['montaNavegacao', 'mostraCarregando']),

    async carregaDados() {
      try {
        this.mostraCarregando(true);
        await this.buscaPermissoesLista()

      } catch (erro) {

      } finally {
        this.mostraCarregando(false);
      }
    },

    async buscaPermissoesLista() {

      this.datatable.carregando = true;
      const query = this.$refs.tabelaPermissao.queryFiltros();

      try {

        await ApiService({
          method: 'POST',
          url: `${API_URL.erp.permissaoNovo.datatable}/?${query}`,
          data: {},
          mostraCarregando: false
        })
        .then(resposta => {
          this.datatable.itens = resposta.data.conteudo.datatable.itens;
          this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
          this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
          this.datatable.carregando = false;
        })
        .catch(erro => {
          this.permissao = false;
          throw new Error(erro.message)
        })

      } catch(erro) {

        Alerta([erro.message], 'error');

      }
      
    },

    // evento para recarregar a página escutado pelo tableBar
    async eventoAtualizarTabela() {
      await this.carregaDados();
    }
  }
}
</script>
