<template>
    <dialog-editar-permissao ref="componenteFilho">
        <v-icon right small @click="disparEventoAbrirOuFecharModal(), dispararEventoPermissaoSelecionadaParaEdicao()">
            mdi-pencil
        </v-icon>
    </dialog-editar-permissao>
</template>

<script>
import DialogEditarPermissao from '../Scaffold/DialogEditarPermissao.vue';

export default {
    name: 'PermissaoLapisEditar',
    components: {
        DialogEditarPermissao
    },
    props: {
        permissao : {
            required: true
        }
    },
    methods: {
        dispararEventoPermissaoSelecionadaParaEdicao() {
            // prepararemos os dados no componente filho
            let permissao = this.permissao     
            this.$refs.componenteFilho.passarValoresParaOFormDeEdicao(permissao)
        },

        disparEventoAbrirOuFecharModal() {
            this.$refs.componenteFilho.eventoAbrirOuFecharModal();
        }
    }
}
</script>