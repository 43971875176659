<template>
  <alerta-itens-selecionados :quantidade="quantidade">
      <strong class="pr-2">Permissões</strong>
  </alerta-itens-selecionados>
</template>

<script>

import AlertaItensSelecionados from '@/components/Shared/AlertaItensSelecionados.vue'

export default {
  name: 'AlertaPermissoesSelecionadas',
  components: {
    AlertaItensSelecionados
  },
  props: ['quantidade']
}
</script>
