<template>
  <v-dialog v-model="dialog" persistent max-width="500">

    <template v-slot:activator="{on, attrs}">
      <slot></slot>
    </template>

    <template>
      <v-card title="Dialog">

        <v-card-title>
          <v-list-item class="grow">

            <span class="text-btn text-uppercase">Criar nova Permissão</span>

            <v-row justify="end">
              <v-icon class="mr-1" large color="red" @click="eventoAbrirOuFecharModal()">
                mdi-close-circle-outline
              </v-icon>
            </v-row>

          </v-list-item>
        </v-card-title>

        <v-divider></v-divider>

          <v-card-text>
            <form-nova-permissao ref="refFormNovaPermissao"></form-nova-permissao>        
          </v-card-text>
        

        <v-card-subtitle>
          <v-btn text color="success" class="ma-2 white--text mb-10" @click="disparaEventoNovaPermissao()">
            SALVAR
          </v-btn>

          <v-btn text plain color="red" class="ma-2 white--text mb-10" @click="eventoAbrirOuFecharModal()">
            CANCELAR
          </v-btn>
        </v-card-subtitle>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>

import FormNovaPermissao from '@/components/Permissao/Scaffold/FormNovaPermissao.vue'

export default {
  name: 'DialogNovaPermissao',
  components: {
    FormNovaPermissao,
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    eventoAbrirOuFecharModal() {
      this.dialog = !this.dialog

    },

    disparaEventoNovaPermissao() {
      this.$refs.refFormNovaPermissao.eventoNovaPermissao()
    },
  }
}
</script>