<template>
  <div>
    <table-bar :icon="'mdi-account-star'" :color="'white'">
      <template v-slot:text>
        Esta tabela lista os usuários que possuem algum perfil atribuido
      </template>

      <template v-slot:buttons>

        <div class="d-flex">

          <div class="pr-2">
            <v-btn elevation="1" color="orange" class="white--text"
              @click="dispararEventoAtualizarTabela()">
              ATUALIZAR
              <v-icon right dark>
                mdi-reload
              </v-icon>
            </v-btn>
          </div>

          <div class="pr-2">
            <dialog-atribuir-permissoes ref="DialogAtribuirPermissoes">
              <v-btn elevation="1" color="green" class="white--text"
                @click="dispararEventoAtribuirPerfil(), disparEventoAbrirOuFecharModal('DialogAtribuirPermissoes')">
                ATRIBUIR PERFIL(S)
                <v-icon right dark>
                  mdi-account-key
                </v-icon>
              </v-btn>
            </dialog-atribuir-permissoes>
          </div>

          <div>
            <dialog-clonar-permissoes ref="DialogClonarPermissoes">
              <v-btn elevation="1" color="blue darken-1" class="white--text"
                @click="disparEventoAbrirOuFecharModal('DialogClonarPermissoes')">
                CLONAR USUARIO
                <v-icon right dark>
                  mdi-account-key
                </v-icon>
              </v-btn>
            </dialog-clonar-permissoes>
          </div>

        </div>

      </template>

    </table-bar>
  </div>
</template>

<script>

import TableBar from '../../Shared/TableBar.vue';
import DialogAtribuirPermissoes from '../Scaffold/DialogAtribuirPermissoes.vue';
import DialogClonarPermissoes from '../Scaffold/DialogClonarPermissoes.vue';

export default {
  name: 'UsuarioPerfilTableBar',
  components: {
    TableBar,
    DialogAtribuirPermissoes,
    DialogClonarPermissoes,
  },
  props: {
    perfisExistentes: {
      required: true
    }
  },
  methods: {

    dispararEventoAtribuirPerfil() {
      this.$emit('disparadoEventoAtribuirPerfil');
      this.$refs.DialogAtribuirPermissoes.perfisExistentes = this.perfisExistentes;
    },

    disparEventoAbrirOuFecharModal(refClicado) {
      this.$refs[refClicado].eventoAbrirOuFecharModal();
    },

    dispararEventoAtualizarTabela() {
      this.$emit('disparadoEventoAtualizarTabela')
    }
  }
}
</script>